svg {
  display: block;
  width: 100%;
  height:100%;
  margin: 2px;
  padding:0px;
}
  svg .sphere {
    fill: #ececec;
  }
  svg .country {
    stroke: #02175A;

  }
  svg  .path{
    stroke-width: 3px;
  }
  svg .markers{
    stroke-width: 1px;
  }
  svg .country:hover{
    fill:#999999
  }
.maplabel{
    font-size: 0.75rem;
    font-weight: 500;
    font-family: arial, helvetica, sans-serif;
    margin: 0;
    fill: rgb(0, 0, 255);
    background: #43A4A8;
  }
